// EXEMPT
/* eslint-disable i18next/no-literal-string */
import React from "react";
import { styled } from "goober";

import { hardCodeURLs } from "@/app/constants.mjs";
import {
  Container,
  ContentWrapper,
  cssStylesLink,
  TextBlock,
  TextBody,
  TextTitle,
} from "@/app/Legal.style.jsx";
import globals from "@/util/global-whitelist.mjs";

const A = styled("a")`
  color: var(--primary) !important;
  text-decoration: underline !important;
  cursor: pointer;
`;

export const Anchor = ({
  to,
  children,
  noBlank = false,
  noExternal = false,
}: React.PropsWithChildren<{
  to: string;
  noBlank?: boolean;
  noExternal?: boolean;
}>) => {
  if (noExternal) {
    return (
      <A target={!noBlank ? "_blank" : ""} href={to} rel="noreferrer">
        {children}
      </A>
    );
  }
  return (
    <A target={!noBlank ? "_blank" : ""} rel="noopener noreferrer" href={to}>
      {children}
    </A>
  );
};

const PrivacyPage = () => {
  const HERE = "here";

  return (
    <Container>
      <ContentWrapper>
        <TextBlock>
          <TextTitle>What information do we collect?</TextTitle>
          <TextBody>
            We collect information from you when you register on our site. When
            ordering or registering on our site, as appropriate, you may be
            asked to enter your: e-mail address. You may, however, visit our
            site anonymously. Google, as a third party vendor, uses cookies to
            serve ads on this site. Google uses of the DART cookie enables it to
            serve ads to your users based on their visit to your sites and other
            sites on the Internet. Users may opt out of the use of the DART
            cookie by visiting the Google ad and content network privacy policy.
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>What do we use your information for?</TextTitle>
          <TextBody>
            <TextBody>
              Any of the information we collect from you may be used in one of
              the following ways:
            </TextBody>
            <ul>
              <li>
                To personalize your experience (your information helps us to
                better respond to your individual needs).
              </li>
              <li>
                To improve our website (we continually strive to improve our
                website offerings based on the information and feedback we
                receive from you).
              </li>
              <li>
                To administer a contest, promotion, survey or other site
                feature.
              </li>
            </ul>
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>How do we protect your information?</TextTitle>
          <TextBody>
            We implement a variety of security measures to maintain the safety
            of your personal information when you enter, submit, or access your
            personal information. We offer the use of a secure server. All
            supplied sensitive/credit information is transmitted via Secure
            Socket Layer (SSL) technology and then encrypted into our Payment
            gateway providers database only to be accessible by those authorized
            with special access rights to such systems, and are required to keep
            the information confidential. After a transaction, your private
            information (credit cards, social security numbers, financials,
            etc.) will not be stored on our servers.
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>Do we use cookies?</TextTitle>
          <TextBody>
            Yes, Cookies are small files that a site or its service provider
            transfers to your computers hard drive through your Web browser (if
            you allow) that enables the sites or service providers systems to
            recognize your browser and capture and remember certain information.
            We use cookies to understand and save your preferences for future
            visits.
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>
            Do we disclose any information to outside parties?
          </TextTitle>
          <TextBody>
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information. This does not include trusted
            third parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect ours or others rights,
            property, or safety. However, non-personally identifiable visitor
            information may be provided to other parties for marketing,
            advertising, or other ueses.
          </TextBody>
          <TextBody>
            When you use our website, we share information that we collect from
            you, such as your email (in hashed form), IP address or information
            about your browser or operating system, with our partner/service
            provider, LiveRamp Inc. LiveRamp returns an online identification
            code that we may store in our first-party cookie for our use in
            online and cross-channel advertising and it may be shared with
            advertising companies to enable interest-based and targeted
            advertising. To opt out of this use, please{" "}
            <Anchor to={hardCodeURLs.LEGAL_OPTOUT}>click here</Anchor>
          </TextBody>
          <TextBody>
            For more information about interest-based ads, or to opt out of
            having your web browsing information used for interest-based
            advertising purposes, please visit{" "}
            <Anchor to="http://www.aboutads.info/choices">
              Your Ad Choices
            </Anchor>
            .
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>
            California Online Privacy Protection Act Compliance
          </TextTitle>
          <TextBody>
            Because we value your privacy we have taken the necessary
            precautions to be in compliance with the California Online Privacy
            Protection Act. We therefore will not distribute your personal
            information to outside parties without your consent. As part of the
            California Online Privacy Protection Act, all users of our site may
            make any changes to their information at anytime by clicking
            {/* eslint-disable-next-line */}
            <span
              className={cssStylesLink()}
              onClick={() => {
                //  @ts-expect-error calling 3rd party api
                globals.uspapi.apply("displayUspUi", 1, function () {});
              }}
            >
              {" "}
              {HERE}
            </span>
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>
            General Data Protection Regulation (GDPR) Compliance
          </TextTitle>
          <TextBody>
            For persons located in the European Economic Area (“EU Data
            Subjects”), commencing on May 25th, 2018 and in case of inquiries to
            our affiliated companies in Europe (such as orders or applications),
            we will process your personal information in accordance with the
            Regulations (EU) 2016/679 of the European Parliament and of the
            Council of 27 April 2016, known as the General Data Protection
            Regulation (GDPR) according to which you have the following rights:
            1.You have the right to information as to whether or not and to
            which extent we process which of your personal information. 2.You
            have the right to object the processing of your data based on
            Article 6 (1) (e), (f) of the GDPR on grounds relating to your
            particular situation at any time. 3.You have the right to
            rectification of any inaccurate personal information about you and,
            taking into account the purposes of the processing, to have any
            incomplete personal data about you completed. 4.You have the right
            to the erasure of your personal information without undue delay
            unless we have a legitimate interest to keep the information, such
            as in the event we need the information to execute an agreement with
            you. 5.You have the right to restrict the processing of your
            personal information, unless we have a legitimate interest to
            continue processing the information for the purpose in respect to
            which you requested the restriction. For example, you have the right
            to object to our processing of your personal information for direct
            marketing purposes (including profiling for direct marketing
            purposes). If you make such an objection, we will cease to process
            your personal information for this purpose. You have the right to
            receive your personal information from us in a structured, commonly
            used and machine-readable format – data portability. However, this
            right does not apply where it would adversely affect the rights and
            freedoms of others. 6.You have the right to withdraw your previously
            given consent at any time. If you are an EU Data Subject and wish to
            exercise any of the foregoing rights, you should contact us at
            legal@blitz.gg. If you believe our processing of your personal
            information violates data protection laws, you have a legal right to
            lodge a complaint with a supervisory authority responsible for data
            protection. You may do so in the EU member state of your habitual
            residence, your place of work or the place of the alleged violation.
            You may exercise any of your rights in this section in relation to
            your personal data by written notice to us at the following email
            address: legal@blitz.gg. For EU Data Subjects, we rely on the
            following legal bases to process your personal information: 1.The
            legal bases for the processing of information we obtain while you
            visit our Sites or use our Services are the performance of a
            contract with respect to the use of our Sites and Services (Art. 6
            Para. 1 lit. b GDPR) and our legitimate interests (Art. 6 Para. 1
            lit. f GDPR) in improving the performance of our Sites and Services.
            2.The legal basis for the processing of information we obtain
            through email messages or forms is your consent (Art. 6 Para. 1 lit.
            a GDPR). 3.The legal basis for the processing of obtained
            information related to your interest in purchasing our products and
            Services is Art. 6 Para. 1 lit. b GDPR (steps at the request of the
            data subject prior to entering into a contract). 4.The legal bases
            for the processing of information we obtain in the course of your
            registration are performance of a contract (Art. 6 Para. 1 lit. b
            GDPR) and your consent (Art. 6 Para. 1 lit. a GDPR). 5.The legal
            basis for the processing of information we obtain through your
            subscription to our e-mail alerts and/or newsletters is your consent
            (Art. 6 Para. 1 lit. a GDPR). 6.The legal basis for the processing
            of information we obtain through the use of cookies, web beacons and
            third party analytics is our legitimate interests (Art. 6 Para. 1
            lit. f GDPR) in improving the performance of our Sites and analyzing
            its use. 7.The legal basis for the transmission of information to
            third-party contractors, agents, business partners, sales
            representatives, or service providers is performance of the contract
            (Art. 6 Para. 1 lit. b GDPR) and legitimate interest (Art. 6 Para. 1
            lit. f GDPR). 8.The legal basis for the transmission of information
            to third parties in case we become involved in a merger,
            acquisition, sale of assets, securities offering, bankruptcy,
            reorganization, dissolution, or any other transaction is our
            legitimate interest (Art. 6 Para. 1 lit. f GDPR) in preparing and
            executing the applicable measure. 9.The legal basis for the
            transmission of information to law enforcement, governmental
            agencies, or authorized third parties is the compliance with a legal
            obligation (Art. 6 Para. 1 lit. c GDPR). 10.The legal basis for the
            transmission of information to our legal counsel and other
            consultants in connection with actual or potential litigation is our
            legitimate interest (Art. 6 Para. 1 lit. f GDPR) in receiving
            consulting services. We will only send EU Data Subjects marketing
            emails or contact EU Data Subjects regarding marketing matters where
            they have agreed to this, such as by subscribing to our newsletters,
            email lists, filling out our contact forms or sending us email
            inquiries. As noted above, we may personalize the message content
            based upon any information you have provided to us and your use of
            the Sites. If you subscribe to a newsletter you will be needed to
            confirm your e-mail address by clicking on a link in an e-mail
            conformation we send you (double-opt-in). Set & edit your privacy
            choices
            {/* eslint-disable-next-line */}
            <span
              className={cssStylesLink()}
              onClick={() => {
                // @ts-expect-error calling 3rd party api
                globals.tcfapi("displayConsentUi", 2, function () {});
              }}
            >
              {" "}
              {HERE}
            </span>
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>
            Childrens Online Privacy Protection Act Compliance
          </TextTitle>
          <TextBody>
            We are in compliance with the requirements of COPPA (Childrens
            Online Privacy Protection Act), we do not collect any information
            from anyone under 13 years of age. Our website, products and
            services are all directed to people who are at least 13 years old or
            older.
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>Online Privacy Policy Only</TextTitle>
          <TextBody>
            This online privacy policy applies only to information collected
            through our website and not to information collected offline.
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>Terms and Conditions</TextTitle>
          <TextBody>
            Please also visit our Terms and Conditions section establishing the
            use, disclaimers, and limitations of liability governing the use of
            our website{" "}
            <Anchor to={"terms-of-service"} noBlank noExternal>
              here
            </Anchor>
            .
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>Your Consent</TextTitle>
          <TextBody>
            By using our site, you consent to our privacy policy.
          </TextBody>
        </TextBlock>
        <TextBlock>
          <TextTitle>Changes to our Privacy Policy</TextTitle>
          <TextBody>
            If we decide to change our privacy policy, we will post those
            changes on this page, and/or update the Privacy Policy modification
            date below. This policy was last modified on October 26, 2023.
          </TextBody>
        </TextBlock>
        <TextBlock className="last-block">
          <TextTitle>Contacting Us</TextTitle>
          <TextBody>
            If there are any questions regarding this privacy policy you may
            contact us using the information here. This policy is powered by
            Trust Guard PCI compliant security scanning.
          </TextBody>
        </TextBlock>
      </ContentWrapper>
    </Container>
  );
};

export default PrivacyPage;

// EXEMPT
import React, { memo } from "react";
import { t } from "i18next";

import BlitzBattlesRules from "@/app/BlitzBattlesRules.jsx";
import {
  LEGAL_BLITZ_BATTLES_RULES,
  LEGAL_PRIVACY_POLICY,
  LEGAL_TABS,
  LEGAL_TOS,
} from "@/app/constants.mjs";
import { Container, cssPageHeaderOverride } from "@/app/Legal.style.jsx";
import PrivacyPolicy from "@/app/PrivacyPolicy.jsx";
import TermsOfService from "@/app/TermsOfService.jsx";
import PageHeader from "@/shared/PageHeader";
import { useRoute } from "@/util/router-hooks.mjs";
import keyInObject from "@/util/key-in-object.mjs";

const TABS = {
  privacypolicy: LEGAL_PRIVACY_POLICY,
  termsofservice: LEGAL_TOS,
  blitzbattlesrules: LEGAL_BLITZ_BATTLES_RULES,
};

const Legal = () => {
  const { parameters } = useRoute();
  const activeTab = parameters[0].replaceAll("-", "");
  const currTab = keyInObject(TABS, activeTab)
    ? TABS[activeTab]
    : LEGAL_PRIVACY_POLICY;

  return (
    <>
      <PageHeader
        title={t("common:legalInfoPolicyInfo", "Legal Info / Policy Info")}
        links={Object.values(LEGAL_TABS).map((tab) => ({
          url: tab.url,
          text: t(tab.title.key, tab.title.value),
        }))}
        className={cssPageHeaderOverride()}
      />
      <Container>
        {currTab === LEGAL_PRIVACY_POLICY && <PrivacyPolicy />}
        {currTab === LEGAL_TOS && <TermsOfService />}
        {currTab === LEGAL_BLITZ_BATTLES_RULES && <BlitzBattlesRules />}
      </Container>
    </>
  );
};

export function meta() {
  return {
    title: ["home:meta.legal.title", "Blitz.gg - Privacy Policy"],
    description: [
      "home:meta.legal.description",
      "We're committed to protecting our users' personal data. Read the Blitz App's privacy policy for more details.",
    ],
  };
}

export default memo(Legal);

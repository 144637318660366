// EXEMPT
/* eslint-disable i18next/no-literal-string */
import React from "react";

import {
  ContentWrapper,
  TextBlock,
  TextBody,
  TextTitle,
} from "@/app/Legal.style.jsx";
import { Anchor } from "@/app/PrivacyPolicy.jsx";

const PRIVACY_POLICY = "/legal/privacy-policy";

const BlitzBattlesRules = () => {
  return (
    <ContentWrapper>
      <TextBlock>
        <TextTitle>Last Updated: 12 July, 2023</TextTitle>
        <TextBody>
          <span className="white">COMPETITION DESCRIPTION:</span>
          <span>&nbsp; Each Blitz Battles challenge (each, a &ldquo;</span>
          <span className="white">Competition</span>
          <span>
            &rdquo;) is a skill-based contest. The Competition begins and ends
            on the dates and times (the &ldquo;
          </span>
          <span className="white">Competition Period</span>
          <span>
            &rdquo;) set forth on the &ldquo;Overview&rdquo; tab in the Blitz
            Battles section of the Blitz app (the &ldquo;
          </span>
          <span className="white">App</span>
          <span>
            &rdquo;). Once you sign up, you can complete in-game objectives in
            the App to earn points to be ranked on a leaderboard. You may
            receive prizes based on your ranking on the leaderboard. Entry in
            the Competition does not constitute entry into any other promotion,
            contest or sweepstakes. By participating in the Competition, each
            entrant unconditionally accepts and agrees to comply with and abide
            by these Official Rules and the decisions of Blitz App, Inc., 5340
            Alla Road, Suite 100, Los Angeles, CA 90066 (&ldquo;
          </span>
          <span className="white">Sponsor</span>
          <span>
            &rdquo;), which shall be final and binding in all respects.{" "}
          </span>
          <span className="white">
            NO PURCHASE NECESSARY TO PARTICIPATE IN THE COMPETITION.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">ELIGIBILITY:</span>
          <span>
            &nbsp; Open only to legal U.S. residents of the fifty (50) United
            States, the District of Columbia and Canada who are thirteen (13)
            years old or older as of time of entry. &nbsp;Officers, directors,
            and employees of Sponsor and its parent, subsidiaries, affiliates,
            distributors, retailers, sales representatives, advertising and
            marketing agencies (all such individuals and entities referred to
            collectively, the &ldquo;
          </span>
          <span className="white">Competition Entities</span>
          <span>
            &rdquo;), and each of their immediate family members and/or people
            living in the same household are NOT eligible to enter the
            Competition or win a prize. &nbsp;This Competition is void where
            prohibited. &nbsp;
          </span>
        </TextBody>
        <TextBody>
          <span className="white">HOW TO ENTER AND WIN:</span>
          <span>
            &nbsp; To enter, register for the Competition in the App and go to
            the &ldquo;Objectives&rdquo; tab within the App. Complete as many
            in-game objectives during the Competition Period to earn points (the
            &ldquo;
          </span>
          <span className="white">Submission</span>
          <span>&rdquo;). </span>
          <span className="white">Limit one (1) entry per person. </span>
          <span>
            &nbsp;There may be limitations on the number of games an entrant can
            play during the Competition Period and/or the number of games the
            earn an entrant points during the Competition Period. &nbsp;Check
            the &ldquo;Overview&rdquo; tab for applicable limitations. The
            eligible contestants with the most points at the end of the
            Competition Period will win the prizes.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">GENERAL CONDITIONS OF ENTRY: &nbsp;</span>
          <span>
            All Submissions must occur during the Competition Period. You must
            have the App open and &nbsp;be signed in to your Blitz account while
            you are playing the game to be able to earn points. If the App is
            not open, or you are not signed in to your Blitz account, your
            in-game actions will not be recorded and cannot be added
            retroactively. In order to use the App, you must link your account
            to your Riot Games account.
          </span>
        </TextBody>
        <TextBody>
          <span>
            Sponsor is the official timekeeper for the Competition. &nbsp;All
            entry information and materials become the property of Sponsor and
            will not be acknowledged or returned. &nbsp;Proof of sending
            information to Sponsor is not considered proof of delivery to or
            receipt by Sponsor of such entry. &nbsp;Except as otherwise
            contemplated in these Official Rules, and to the extent entrants may
            otherwise elect at the time of entry, personal information collected
            in connection with the Competition will be used in accordance with
            Sponsor&rsquo;s online privacy policy, located at{" "}
          </span>
          <span>
            <Anchor to={PRIVACY_POLICY} noBlank noExternal>
              Privacy Policy
            </Anchor>
          </span>
          <span>
            . &nbsp;Any communication of information transmitted to Sponsor by
            e-mail or otherwise is and will be treated as non-confidential and
            non-proprietary.
          </span>
        </TextBody>
        <TextBody>
          <span>
            Entry must be made by the entrant, only as described in these
            Official Rules. Sponsor shall have no liability for any Submission
            that is lost, intercepted or not received by the Sponsor. Entries
            made by any other individual or any entity, and/or originating at
            any other website or e-mail address, including but not limited to
            commercial sweepstakes subscription notification and/or entering
            service websites, will be declared invalid and disqualified for this
            Competition. Tampering with the entry process or the operation of
            the Competition, including but not limited to the use of any device
            to automate the entry process, is prohibited and any entries deemed
            by Sponsor, in its sole discretion, to have been submitted in this
            manner will be void. In the event a dispute regarding the identity
            of the individual who actually submitted an entry cannot be resolved
            to Sponsor&rsquo;s satisfaction, the affected entry will be deemed
            ineligible. The Competition Entities shall not be responsible for
            incorrect or inaccurate entry information whether caused by Internet
            users or by any of the equipment or programming associated with or
            utilized in the Competition or by any technical or human error which
            may occur in the processing of the entries in the Competition. The
            Competition Entities assume no responsibility or liability for any
            error, omission, interruption, deletion, theft or destruction, or
            unauthorized access to, or alteration of entries.
          </span>
        </TextBody>
        <TextBody>
          <span>
            Smurfing (i.e. playing on an alternate account of a lower rank) and
            other in-game cheating is not permitted. &nbsp;Sponsor reserves the
            right in its sole discretion to disqualify any individual believed
            to have cheated. Any in-game overlay that calculates points on a
            real-time basis is approximate; your actual point total will be that
            displayed in the App. &nbsp;Points may take several minutes to be
            reflected in the App. If you skip your stats and the post-match
            screen within the App, it may take longer for your points to be
            reflected in the App.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">PRIZES:</span>
          <span>
            &nbsp;See the &ldquo;Rewards&rdquo; tab in the App for prize
            details. Competitions may involve a prize partner or other brand
            partner (&ldquo;
          </span>
          <span className="white">Prize Partner</span>
          <span>
            &rdquo;) who supplies prizes for a Competition, receives branding in
            connection with a Competition and/or receives certain aggregated and
            de-identified information about the Competition. For Competitions
            involving a Prize Partner, the Prize Partner will have no liability
            or responsibility for the Competition or the prize distribution but
            will be solely responsible for honoring any prize gift codes,
            coupons or vouchers. &nbsp;The Prize Partner is intended to be a
            third-party beneficiary of these Terms.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">GENERAL PRIZE CONDITIONS:</span>
          <span>
            &nbsp;Gift cards are subject to the terms and conditions printed
            thereon and imposed by issuer. No cash alternative or substitution
            of prizes will be allowed, except Sponsor reserves the right in its
            sole discretion to substitute prizes of comparable value if any
            prize listed is unavailable, in whole or in part, for any reason.
            Prizes will be awarded only if the potential prize winner fully
            complies with these Official Rules. All portions of the prize(s) are
            non-assignable and non-transferable. Any prizes pictured in
            point-of-sale, online, television and print advertising, promotional
            packaging, and other Competition materials are for illustrative
            purposes only. &nbsp;All details and other restrictions of the
            prize(s) not specified in these Official Rules will be determined by
            Sponsor in its sole discretion.{" "}
          </span>
          <span className="white">
            EACH PRIZE WINNER SHALL BE SOLELY RESPONSIBLE FOR ALL FEDERAL, STATE
            AND/OR LOCAL TAXES, AND THE REPORTING CONSEQUENCES THEREOF, AND FOR
            ANY OTHER FEES OR COSTS ASSOCIATED WITH THE APPLICABLE PRIZE. &nbsp;
          </span>
          <span className="white">
            ALL PRIZES ARE PROVIDED &ldquo;AS IS&rdquo; WITHOUT WARRANTY OF ANY
            KIND, EITHER EXPRESS OR IMPLIED, AND SPONSOR HEREBY DISCLAIMS ALL
            SUCH WARRANTIES, INCLUDING BUT NOT LIMITED TO, THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
            AND/OR NON-INFRINGEMENT.
          </span>
          <span>&nbsp; </span>
        </TextBody>
        <TextBody>
          <span className="white">WINNER SELECTION:</span>
          <span>
            &nbsp;At the end of the Competition Period, prize winners will be
            selected by Sponsor in its sole and absolute discretion based on the
            entrants&rsquo; ranking on the leaderboard. All results of the
            selection are final and binding, subject to these Official Rules. In
            the event a winning entry is discovered to be invalid for any reason
            whatsoever or the person who submitted the winning entry fails to
            comply with these Official Rules prior to delivery of the prize, the
            prize may be forfeited and awarded to an alternate winner. &nbsp;No
            more than the advertised number of prizes will be awarded.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">NOTICE TO WINNERS</span>
          <span>
            : Attempts to notify potential winners will be made via email.
            Sponsor is not responsible for communication problems of any kind.
            Time is of the essence in awarding the prizes. If, despite
            reasonable efforts, a potential winner does not respond within five
            (5) days of the first notification attempt (or such shorter time as
            exigencies may require), or if the prize or prize notification is
            returned as unclaimed or undeliverable to such potential winner,
            such potential winner may forfeit any prize and an alternate winner
            may be selected.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">FURTHER DOCUMENTATION AND PUBLICITY</span>
          <span>
            : &nbsp;Potential winners may be required to execute an Affidavit of
            Eligibility, a Liability Release, and (where imposing such condition
            is legal) a Publicity Release (collectively, &quot;
          </span>
          <span className="white">Prize Claim Documents</span>
          <span>
            &quot;). &nbsp;If any potential winner fails or refuses to sign and
            return all Prize Claim Documents within five (5) days of prize
            notification (or such shorter time as exigencies may require), the
            winner may be disqualified and an alternate winner may be selected.
            &nbsp;As part of the Prize Claim Documents, a potential winner may
            also be required to execute a further right of publicity release to
            use the winner&rsquo;s name and/or image, including, but not limited
            to, for advertising and promotional purposes, without further
            compensation.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">
            DATES &amp; DEADLINES/ANTICIPATED NUMBER OF ENTRANTS
          </span>
          <span>
            : Because of the unique nature and scope of the Competition, Sponsor
            reserves the right, in addition to those other rights reserved
            herein, to modify any date(s) or deadline(s) set forth in these
            Official Rules or otherwise governing the Competition. &nbsp;Sponsor
            cannot accurately predict the number of entrants who will
            participate in the Competition.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">
            GENERAL LIABILITY RELEASE/FORCE MAJEURE:
          </span>
          <span>
            &nbsp; Entry in the Competition constitutes entrant&rsquo;s
            permission for the Competition Entities to use entrant&rsquo;s name,
            photograph, likeness, voice, biographical information, statements,
            and address (city and state) for advertising and/or publicity
            purposes worldwide and in all forms of media now known or hereafter
            developed, in perpetuity, without further compensation. Entrants
            agree that the Competition Entities (A) shall not be responsible or
            liable for, and are hereby released from, any and all costs,
            injuries, losses or damages of any kind, including, without
            limitation, death and bodily injury, due in whole or in part,
            directly or indirectly, to participation in the Competition or any
            Competition-related activity, or from entrants&rsquo; acceptance,
            receipt, possession and/or use or misuse of any prize, and (B) have
            not made any warranty, representation or guarantee express or
            implied, in fact or in law, with respect to any prize, including,
            without limitation, to such prize&rsquo;s quality or fitness for a
            particular purpose. &nbsp;Sponsor assumes no responsibility for any
            damage to an entrant&rsquo;s, or any other person&rsquo;s, computer
            system which is occasioned by participating in the Competition, or
            for any computer system, phone line, hardware, software or program
            malfunctions, or other errors, failures, delayed computer
            transmissions or network connections that are human or technical in
            nature. Without limiting the generality of the foregoing, Sponsor is
            not responsible for incomplete, illegible, misdirected, misprinted,
            late, lost, damaged, stolen, or postage-due prize notifications; or
            for lost, interrupted, inaccessible or unavailable networks,
            servers, satellites, Internet service providers, websites, or other
            connections; or for miscommunications, failed, jumbled, scrambled,
            delayed, or misdirected computer, telephone or cable transmissions;
            or for any technical malfunctions, failures, difficulties or other
            errors of any kind or nature; or for the incorrect or inaccurate
            capture of information, or the failure to capture any information.{" "}
          </span>
          <span className="white">
            SPONSOR RESERVES THE RIGHT IN ITS SOLE DISCRETION TO DISQUALIFY ANY
            INDIVIDUAL WHO IS FOUND TO BE TAMPERING WITH THE ENTRY PROCESS OR
            THE OPERATION OF THE COMPETITION, TO BE ACTING IN VIOLATION OF THESE
            OFFICIAL RULES, OR TO BE ACTING IN AN UNSPORTSMANLIKE OR DISRUPTIVE
            MANNER, OR WITH THE INTENT TO DISRUPT OR UNDERMINE THE LEGITIMATE
            OPERATION OF THE COMPETITION, OR TO ANNOY, ABUSE, THREATEN OR HARASS
            ANY OTHER PERSON, AND SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES AND
            OTHER REMEDIES FROM ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED
            BY LAW.
          </span>
          <span>
            &nbsp;No mechanically reproduced, illegible, incomplete, forged,
            software-generated or other automated multiple entries will be
            accepted. &nbsp;Sponsor reserves the right to modify, extend,
            suspend, or terminate the Competition if it determines, in its sole
            discretion, that the Competition is technically impaired or
            corrupted or that fraud or technical problems, failures, or
            malfunctions or other causes beyond Sponsor&rsquo;s control have
            destroyed or severely undermined or to any degree impaired the
            integrity, administration, security, proper play and/or feasibility
            of the Competition as contemplated herein. &nbsp;In the event an
            insufficient number of eligible entries are received and/or Sponsor
            is prevented from awarding prizes or continuing with the Competition
            as contemplated herein by any event beyond its control, including
            but not limited to fire, flood, natural or man-made epidemic of
            health of other means, earthquake, explosion, labor dispute or
            strike, act of God or public enemy, satellite or equipment failure,
            riot or civil disturbance, terrorist threat or activity, war
            (declared or undeclared) or any federal state or local government
            law, order, or regulation, public health crisis, order of any court
            or jurisdiction, or other cause not reasonably within
            Sponsor&rsquo;s control (each a &ldquo;
          </span>
          <span className="white">Force Majeure</span>
          <span>
            &rdquo; event or occurrence), then subject to any governmental
            approval which may be required, Sponsor shall have the right to
            modify, suspend, extend, or terminate the Competition. &nbsp;If the
            Competition is terminated before the designated end date, Sponsor
            will (if possible) select the winner(s) from all eligible,
            non-suspect entries received as of the date of the event giving rise
            to the termination. &nbsp;Inclusion in such drawing shall be each
            entrant&rsquo;s sole and exclusive remedy under such circumstances.
            Only the type and quantity of prizes described in these Official
            Rules will be awarded. These Official Rules cannot be modified or
            amended in any way except in a written document issued in accordance
            with law by a duly authorized representative of Sponsor.
            &nbsp;Unless otherwise stated in these Official Rules, the
            invalidity or unenforceability of any provision of these Official
            Rules shall not affect the validity or enforceability of any other
            provision. Unless otherwise stated in these Official Rules, in the
            event that any provision is determined to be invalid or otherwise
            unenforceable or illegal, these Official Rules shall otherwise
            remain in effect and shall be construed in accordance with their
            terms as if the invalid or illegal provision were not contained
            herein. &nbsp;The Blitz App is compliant with Riot Games&rsquo;
            terms and conditions. In the event the Blitz App or the Competition
            conflict with or are found to be in violation of Riot Games&rsquo;
            terms and conditions or other rules, Blitz may alter, or terminate a
            Competition at any time.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">GOVERNING LAW/JURISDICTION:</span>
          <span>
            &nbsp;ALL ISSUES AND QUESTIONS CONCERNING THE CONSTRUCTION,
            VALIDITY, INTERPRETATION AND ENFORCEABILITY OF THESE OFFICIAL RULES
            OR THE RIGHTS AND OBLIGATIONS OF ENTRANTS OR SPONSOR IN CONNECTION
            WITH THE COMPETITION SHALL BE GOVERNED BY AND CONSTRUED IN
            ACCORDANCE WITH THE INTERNAL LAWS OF THE STATE OF CALIFORNIA WITHOUT
            GIVING EFFECT TO ANY CHOICE OF LAW OR{" "}
          </span>
          <span>
            CONFLICT OF LAW RULES OR PROVISIONS THAT WOULD CAUSE THE APPLICATION
            OF ANY OTHER STATE&rsquo;S LAWS. &nbsp;FOR THE PURPOSES OF ANY
            DISPUTES HEREUNDER, BY ENTERING THIS COMPETITION, EACH ENTRANT
            AGREES THAT ANY AND ALL DISPUTES, CLAIMS, AND CAUSES OF ACTION
            ARISING OUT OF OR IN ANY WAY RELATED TO THIS COMPETITION SHALL BE
            RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION
            AND, SUBJECT TO THE ARBITRATION PROVISION BELOW, CONSENTS TO THE
            EXCLUSIVE JURISDICTION AND VENUE OF THE APPROPRIATE STATE OR FEDERAL
            COURT SITUATED IN LOS ANGELES, CALIFORNIA.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">ARBITRATION PROVISION</span>
          <span>
            : By participating in this Competition, entrant agrees that any and
            all controversies, claims, counterclaims or other disputes entrant
            may have with, or claims entrant may have against the Competition
            Entities relating to, arising out of or connected in any way with
            (a) the Competition, (b) the awarding or redemption of any prize,
            and/or (c) the determination of the scope or applicability of this
            agreement to arbitrate (a &ldquo;
          </span>
          <span className="white">Claim</span>
          <span>
            &rdquo;), will be resolved exclusively by final and binding
            arbitration in accordance with the Streamlined Arbitration
            Procedures of JAMS.&nbsp;The arbitration will be heard and
            determined by a single arbitrator.&nbsp;The arbitrator&#39;s
            decision in any such arbitration will be final and binding upon the
            parties and may be enforced in any court of competent
            jurisdiction.&nbsp;The parties agree that the arbitration
            proceedings will be kept confidential and that the existence of the
            proceeding and any element of it will not be disclosed beyond the
            arbitration proceedings, except as may lawfully be required in
            judicial proceedings relating to the arbitration, by applicable
            disclosure rules and regulations of securities regulatory
            authorities or other governmental agencies, or as specifically
            permitted by state law. The Federal Arbitration Act and federal
            arbitration law apply to this agreement.&nbsp;However, the
            Arbitrator, and not any federal, state, or local court or agency,
            shall have the exclusive authority to resolve any dispute relating
            to the interpretation, applicability, enforceability, or formation
            of these Official Rules including, but not limited to, a claim that
            all or any part of these Official Rules is void or voidable.
            &nbsp;Arbitration will take place in Los Angeles, California or, if
            requested, some other location in the United States that is
            reasonably convenient to you.
          </span>
        </TextBody>
        <TextBody>
          <span>
            If entrant demonstrates that the costs of arbitration will be
            prohibitive as compared to the costs of litigation, Sponsor will pay
            as much of the administrative costs and arbitrator&rsquo;s fees
            required for the arbitration as the arbitrator deems necessary to
            prevent the cost of the arbitration from being prohibitive.&nbsp;In
            the final award, the arbitrator may apportion the costs of
            arbitration and the compensation of the arbitrator among the parties
            in such amounts as the arbitrator deems appropriate.
          </span>
        </TextBody>
        <TextBody>
          <span>
            This arbitration agreement does not preclude entrant from seeking
            action by federal, state, or local government agencies.&nbsp;Entrant
            and Sponsor also have the right to bring qualifying claims in small
            claims court.&nbsp; In addition, entrant and Sponsor retain the
            right to apply to any court of competent jurisdiction for
            provisional relief, including pre-arbitral attachments or
            preliminary injunctions, and any such request shall not be deemed
            incompatible with these Official Rules, nor a waiver of the right to
            have disputes submitted to arbitration as provided in these Official
            Rules.&nbsp;
          </span>
        </TextBody>
        <TextBody>
          <span>
            Neither entrant nor Sponsor may act as a class representative, nor
            participate as a member of a class of claimants, with respect to any
            Claim.&nbsp;Claims may not be arbitrated on a class or
            representative basis.&nbsp;The arbitrator can decide only Entrant
            and/or Sponsor&rsquo;s individual Claims.&nbsp;The arbitrator may
            not consolidate or join the claims of other persons or parties who
            may be similarly situated. If for any reason a claim proceeds in
            court rather than in arbitration we each waive any right to a jury
            trial.&nbsp;Notwithstanding anything to the contrary herein, the
            arbitrator may award in the arbitration the same damages or other
            relief available under applicable law, including injunctive and
            declaratory relief (which may include public injunctive relief), as
            if the action were brought in court on an individual basis.
          </span>
        </TextBody>
        <TextBody>
          <span>
            THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO MAINTAIN
            A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE
            IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE RIGHT TO ENGAGE IN
            DISCOVERY EXCEPT AS PROVIDED IN AAA RULES, AND THE RIGHT TO CERTAIN
            REMEDIES AND FORMS OF RELIEF.&nbsp; OTHER RIGHTS THAT ENTRANT OR
            SPONSOR WOULD HAVE IN COURT ALSO MAY NOT BE AVAILABLE IN
            ARBITRATION. If any provision of this Section is found to be invalid
            or unenforceable, then that specific provision shall be of no force
            and effect and shall be severed, but the remainder of this Section
            will continue in full force and effect.&nbsp;No waiver of any
            provision of this Section of these Official Rules will be effective
            or enforceable unless recorded in a writing signed by the party
            waiving such a right or requirement.&nbsp;Such a waiver shall not
            waive or affect any other portion of these Official Rules. This
            Section of these Official Rules will survive the termination of your
            relationship with Sponsor.
          </span>
        </TextBody>
        <TextBody>
          <span className="white">WINNER&rsquo;S LIST/OFFICIAL RULES:</span>
          <span>
            &nbsp;To obtain any legally-required winners list (after the
            conclusion of the Competition) or a copy of these Official Rules,
            send a self-addressed envelope with the proper postage affixed to:
            Blitz App, Inc., 6080 Center Drive, Floor 12 Los Angeles, CA 90045.
          </span>
        </TextBody>
        <TextBody>
          <span>
            Please specify &quot;winners list&quot; or &quot;Official
            Rules&quot; and the name of the Competition in your request.
          </span>
        </TextBody>
      </TextBlock>
    </ContentWrapper>
  );
};

export default BlitzBattlesRules;

import { css, styled } from "goober";
import { HD, mobile, mobileMedium, tablet } from "clutch/src/Style/style.mjs";

export const Container = styled("div")`
  max-width: var(--sp-container);
  margin: var(--sp-6) auto;
`;

export const Root = styled("div")`
  margin-top: var(--sp-16);
`;

export const cssStylesLink = () => css`
  color: var(--primary);
  text-decoration: underline;
  &:hover {
    color: var(--white);
    cursor: pointer;
  }
  &:active {
    color: var(--shade1);
  }
`;

export const PageWrapper = styled("div")`
  display: flex;
  padding-top: var(--sp-16);
  padding-bottom: calc(var(--sp-12) * 2);

  ${HD} {
    width: calc(100% - (var(--sp-10) * 6)) !important;
    margin: var(--sp-0) calc(var(--sp-10) * 3);
  }

  ${tablet} {
    flex-direction: column;
    width: calc(100% - (var(--sp-16) * 2)) !important;
    margin: var(--sp-0) var(--sp-16);
  }

  ${mobile} {
    width: calc(100% - var(--sp-16)) !important;
    padding-top: var(--sp-8);
    padding-bottom: var(--sp-10);
    margin: var(--sp-0) var(--sp-8) !important;
    box-sizing: border-box;
  }

  ${mobileMedium} {
    width: calc(100% - var(--sp-12)) !important;
    margin: var(--sp-0) var(--sp-6) !important;
    padding-top: var(--sp-6);
    padding-bottom: var(--sp-2);
  }
`;

export const DropdownSelectBlock = styled("div")`
  display: none;
  margin-bottom: var(--sp-16);

  ${tablet} {
    display: block;
  }

  ${mobileMedium} {
    margin-bottom: var(--sp-8);
  }

  .type-article-headline {
    color: var(--white);
    margin-bottom: var(--sp-2);
  }

  .type-body1 {
    color: var(--shade1);
    margin-bottom: var(--sp-6);
  }

  .selectDropdownOuter {
    width: 100%;
    height: var(--sp-13) !important;
    background: var(--shade6) !important;
    border-radius: var(--sp-2);

    & > div {
      height: 100% !important;
    }

    & > div > div {
      height: 100% !important;
      padding-left: var(--sp-0) !important;
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    height: calc(100% - var(--sp-0_5));
    background: var(--shade6);
    color: var(--white) !important;
    margin: var(--sp-px) var(--sp-0) var(--sp-px) var(--sp-6);

    .text-number {
      color: var(--primary);
      margin-right: var(--sp-0);
    }

    &:hover {
      background: var(--shade6);
    }
  }
`;

export const NavigationBlock = styled("div")`
  width: 25.5%;
  max-width: 306px;
  margin-right: var(--sp-12);

  ${tablet} {
    display: none;
  }

  ${mobileMedium} {
    margin-right: var(--sp-6);
  }

  p {
    padding-bottom: var(--sp-6);
  }

  .select-item {
    background: var(--shade8);
    padding: var(--sp-4) var(--sp-6);
    border-radius: var(--sp-2);
    margin-bottom: var(--sp-3);
    cursor: pointer;

    &:hover,
    &.active {
      background: var(--shade6);
      box-shadow: inset 0px 1px 0px rgba(227, 229, 234, 0.25);
    }
  }
`;

export const ContentWrapper = styled("div")`
  flex: 1;

  h3 {
    color: var(--shade0);
    padding-bottom: var(--sp-6);
    border-bottom: var(--sp-px) solid var(--shade0-15);
    margin-bottom: var(--sp-6);
  }

  ${mobile} {
    h3 {
      padding-bottom: var(--sp-8);
    }
  }

  ${mobileMedium} {
    h3 {
      padding-bottom: var(--sp-6);
    }
  }
`;

export const TextBlock = styled("div")`
  margin-bottom: var(--sp-6);
  padding: var(--sp-0);
  border-bottom: var(--sp-px) dashed var(--shade5);

  &.last-block {
    border: none;
  }

  ul {
    list-style: disc;
    margin-left: var(--sp-7);
  }

  ${mobile} {
    padding: var(--sp-5) var(--sp-0);
  }

  ${mobileMedium} {
    padding: var(--sp-3) var(--sp-0);
  }
`;

export const TextTitle = styled("p")`
  color: var(--white);
  font-weight: 600;
  font-size: var(--sp-4_5);
  line-height: var(--sp-7);
  letter-spacing: -0.009em;
  && {
    padding-bottom: var(--sp-6);
  }
`;

export const TextBody = styled("div")`
  color: var(--shade1);
  font-weight: 500;
  font-size: var(--sp-4e);
  letter-spacing: -0.009em;

  &.uppercase {
    color: var(--shade0);
    text-transform: uppercase;
  }
  && {
    padding-bottom: var(--sp-6);
  }
`;

export const TextSubBlock = styled("div")`
  margin-bottom: var(--sp-2);

  &.last-sub-block {
    margin-bottom: var(--0);
  }
`;

export const TextSubTitle = styled("div")`
  color: var(--white);
  font-weight: 500;
  font-size: var(--sp-4e);
  letter-spacing: -0.009em;
  && {
    padding-bottom: var(--sp-4);
  }
`;

export const cssPageHeaderOverride = () => css`
  height: var(--sp-4);
`;
